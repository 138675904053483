<template lang="pug">
  div(v-if="!fieldbook")
    .card-panel
      h4.center
        i.fa.fa-spinner.fa-spin
        span &nbsp;
        span Loading Field Book
  div(v-if="fieldbook")
    .card-panel.center
      h4
        i.fa.fa-book
        span {{ fieldbook.event.name }} {{ fieldbook.market.name }} {{ formatScheduledOffTime(fieldbook.market.scheduled_off_time) }}
      .button-bar
        button.btn(@click="refreshFieldbook" :disabled="refreshDisabled")
          i.material-icons.left refresh
          span(v-if="!refreshDisabled") Refresh
          span(v-else) Refresh available in {{ refreshCountdown }}
      br
      .chip
        b Bets:
        span &nbsp;
        span(id="bet_count") {{ betCount }}
      .chip
        b Calculated at:
        span &nbsp;
        span(id="created_at") {{ formatScheduledOffTime(fieldbook.created_at) }}

    .controls
      .search-bar
        input(type="text" v-model="searchQuery" placeholder="Search competitors by name..." class="search-input")

      .sort-dropdown
        label Sort by:
        select(v-model="sortKey" id="sorting" class="browser-default")
          option(value="card_number") Card Number
          option(value="name") Name
          option(value="price") Price
          option(value="other_take") Other Take
          option(value="other_payout") Other Pay
          option(value="singles_take") Singles Take
          option(value="singles_pay") Singles Pay
          option(value="singles_pl") Singles Profit/Loss

        button.btn(@click="toggleSortOrder") {{ sortOrder === 'asc' ? '▲' : '▼' }}

    .table-container(v-if="fieldbook")
      table.striped.m-0
        thead
          tr
          th(@click="sortBy('card_number')") Card Number {{ getSortIndicator('card_number') }}
          th(@click="sortBy('name')") Competitor {{ getSortIndicator('name') }}
          th(@click="sortBy('price')")
            .right Price {{ getSortIndicator('price') }}
          th(@click="sortBy('other_take')")
            .right Other Take {{ getSortIndicator('other_take') }}
          th(@click="sortBy('other_payout')")
            .right Other Payout {{ getSortIndicator('other_payout') }}
          th(@click="sortBy('singles_take')")
            .right Singles Take {{ getSortIndicator('singles_take') }}
          th(@click="sortBy('singles_pay')")
            .right Singles Pay {{ getSortIndicator('singles_pay') }}
          th(@click="sortBy('singles_pl')")
            .right Singles P/L {{ getSortIndicator('singles_pl') }}
          th.center Result
        tbody
          tr(v-for="(cc, index) in sortedCompetitors", :key="cc.competitor.id")
            td.wide-only {{ cc.competitor.card_number }}
            td.wide-only
              a(:href="competitorPath(cc.competitor)") {{ cc.competitor.name }}
            td.mobile-only(:data-label="'Competitor'")
              .right
                span {{ cc.competitor.card_number }} &nbsp;
                a(:href="competitorPath(cc.competitor)") {{ cc.competitor.name }}
            td.center(:data-label="'Price'")
              .right(:id="'price-' + cc.competitor.id")
                b {{ cc.competitor.latest_price.fractional }}
                span &nbsp;
                small {{ cc.competitor.latest_price.type }}
            td.center(:data-label="'Other Take'")
              .right.field-book-value(:id="'other_stakes-' + cc.competitor.id") {{ formatCurrency(cc.other_stakes) }}
            td.center(:data-label="'Other Payout'")
              .right.field-book-value(:id="'other_potential_returns-' + cc.competitor.id") {{ formatCurrency(cc.other_potential_returns) }}
            td.center(:data-label="'Singles Take'")
              .right.field-book-value(:id="'singles_stakes-' + cc.competitor.id") {{ formatCurrency(cc.singles_stakes) }}
            td.center(:data-label="'Singles Pay'")
              .right.field-book-value(:id="'singles_potential_returns-' + cc.competitor.id") {{ formatCurrency(cc.singles_potential_returns) }}
            td.center(:data-label="'Singles P/L'")
              .right.field-book-value(:id="'singles_profit_loss-' + cc.competitor.id") {{ formatCurrency(cc.singles_profit_loss) }}
            td(:data-label="'Result'")
              i.material-icons.left.green-text(v-if="cc.competitor.current_result && cc.competitor.current_result.position == '1'") check
          tr
            td.mobile-only(data-label="Totals")
            td.wide-only Totals
            td.wide-only
            td.wide-only
            td(data-label="Other Take")
              .right.field-book-value(id="other_stakes") {{ formatCurrency(fieldbook.other_stakes) }}
            td.wide-only
            td(data-label="Singles Take")
              .right.field-book-value(id="singles_stakes") {{ formatCurrency(fieldbook.singles_stakes) }}
            td.center.wide-only
            td.center.wide-only
 </template>

<script>
import { ref, computed, onMounted } from 'vue';
import axios_client from '../utils/axios_client';
import moment from 'moment';

export default {
  props: {
    market_id: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const fieldbook = ref(null);
    const refreshDisabled = ref(false);
    const refreshCountdown = ref(0);
    const refreshInterval = ref(null);
    const searchQuery = ref('');
    const sortKey = ref('price');
    const sortOrder = ref('asc');

    const formatScheduledOffTime = (scheduledOffTime) => {
      return moment(scheduledOffTime).format('YYYY-MM-DD HH:mm:ss');
    };

    const competitorPath = competitor => {
      return competitor?.market?.event?.id && competitor?.market?.id && competitor?.id
        ? `/events/${competitor.market.event.id}/markets/${competitor.market.id}/competitors/${competitor.id}`
        : '#';
    };

    const formatCurrency = amount => {
      if (!amount) return '€0.00';
      return new Intl.NumberFormat('en-IE', { style: 'currency', currency: 'EUR' }).format(amount);
    };

    const startRefreshCooldown = () => {
      if (refreshInterval.value) {
        clearInterval(refreshInterval.value);
      }
      refreshDisabled.value = true;
      refreshCountdown.value = 20;

      refreshInterval.value = setInterval(() => {
        refreshCountdown.value--;
        if (refreshCountdown.value <= 0) {
          clearInterval(refreshInterval.value);
          refreshCountdown.value = 0;
          refreshDisabled.value = false;
        }
      }, 1000);
    };

    const fetchFieldBooks = async () => {
      try {
        const response = await axios_client.get(`${props.market_id}/field-book.json`);

        if (response.data) {
          fieldbook.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }

        startRefreshCooldown();

      } catch (error) {
        console.error(
          'Error fetching field book:',
          error.response ? error.response.data : error.message,
        );
      }
    };

    // Our refresh method
    const refreshFieldbook = async () => {
      // Guard clause in case the user clicks quickly
      if (refreshDisabled.value) return;

      // Disable immediately to prevent repeated clicks
      refreshDisabled.value = true;

      // Perform the fetch
      await fetchFieldBooks();
    };

    const betCount = computed(() => {
      if (!fieldbook.value) return 0;
      return fieldbook.value.bet_count;
    });

    const filteredCompetitors = computed(() => {
      if (!fieldbook.value || !fieldbook.value.calculated_competitors) return [];
      return fieldbook.value.calculated_competitors.filter(cc =>
        cc.competitor.name.toLowerCase().includes(searchQuery.value.toLowerCase()),
      );
    });

    const sortedCompetitors = computed(() => {
      let sorted = [...filteredCompetitors.value];

      sorted.sort((a, b) => {
        let aValue, bValue;

        switch (sortKey.value) {
          case 'card_number':
            aValue = a.competitor.card_number;
            bValue = b.competitor.card_number;
            break;
          case 'name':
            aValue = a.competitor.name.toLowerCase();
            bValue = b.competitor.name.toLowerCase();
            return sortOrder.value === 'asc'
              ? aValue.localeCompare(bValue)
              : bValue.localeCompare(aValue);
          case 'price':
            aValue = a.competitor.latest_price.decimal;
            bValue = b.competitor.latest_price.decimal;
            break;
          case 'other_take':
            aValue = a.other_stakes;
            bValue = b.other_stakes;
            break;
          case 'other_payout':
            aValue = a.other_potential_returns;
            bValue = b.other_potential_returns;
            break;
          case 'singles_take':
            aValue = a.singles_stakes;
            bValue = b.singles_stakes;
            break;
          case 'singles_pay':
            aValue = a.singles_potential_returns;
            bValue = b.singles_potential_returns;
            break;
          case 'singles_pl':
            aValue = a.singles_profit_loss;
            bValue = b.singles_profit_loss;
            break;
          default:
            return 0;
        }

        return sortOrder.value === 'asc' ? aValue - bValue : bValue - aValue;
      });

      return sorted;
    });

    const sortBy = key => {
      if (sortKey.value === key) {
        sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
      } else {
        sortKey.value = key;
        sortOrder.value = 'asc';
      }
    };

    const toggleSortOrder = () => {
      sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
    };

    const getSortIndicator = key => {
      if (sortKey.value === key) {
        return sortOrder.value === 'asc' ? '▲' : '▼';
      }
      return '';
    };

    const setupWebSocket = () => {
      consumer.subscriptions.create('FieldbookChannel', {
        received(data) {
          updateFieldBookUI(data);
        },
      });
    };

    const animateValueChange = (selector) => {
      anime({
        targets: selector,
        scale: [1, 1.5, 1],
        duration: 500,
        easing: "easeInOutQuad",
      });
    };

    const updateFieldBookUI = (updatedFieldbook) => {
      if (!fieldbook.value) return;

      fieldbook.value.created_at = updatedFieldbook.created_at;
      animateValueChange('#created_at');

      if (parseInt(fieldbook.value.bet_count) !== parseInt(updatedFieldbook.bet_count)) {
        fieldbook.value.bet_count = updatedFieldbook.bet_count;
        animateValueChange('#bet_count');
      }

      if (parseFloat(fieldbook.value.other_stakes) !== parseFloat(updatedFieldbook.other_stakes)) {
        fieldbook.value.other_stakes = updatedFieldbook.other_stakes;
        animateValueChange('#other_stakes');
      }
      if (parseFloat(fieldbook.value.singles_stakes) !== parseFloat(updatedFieldbook.singles_stakes)) {
        fieldbook.value.singles_stakes = updatedFieldbook.singles_stakes;
        animateValueChange('#singles_stakes');
      }

      fieldbook.value.calculated_competitors.forEach((cc, index) => {
        const updatedCompetitor = updatedFieldbook.calculated_competitors[index];
        if (updatedCompetitor) {
          const fields = [
            "other_stakes",
            "other_potential_returns",
            "singles_stakes",
            "singles_potential_returns",
            "singles_profit_loss"
          ];

          fields.forEach((field) => {
            if (parseFloat(cc[field]) !== parseFloat(updatedCompetitor[field])) {
              cc[field] = updatedCompetitor[field];

              animateValueChange(`#${field}-${cc.competitor.id}`);
            }
          });
          if (parseFloat(cc.competitor.latest_price.decimal) !== parseFloat(updatedCompetitor.competitor.latest_price.decimal)) {
            cc.competitor.latest_price = updatedCompetitor.competitor.latest_price;
            animateValueChange(`#price-${cc.competitor.id}`);
          }
        }
      });


      const audio = new Audio(window.alert1Path);
      audio.play();
    };

    onMounted(() => {
      fetchFieldBooks();
    });

    return {
      betCount,
      formatScheduledOffTime,
      fieldbook,
      competitorPath,
      formatCurrency,
      searchQuery,
      filteredCompetitors,
      sortKey,
      sortOrder,
      sortedCompetitors,
      toggleSortOrder,
      sortBy,
      getSortIndicator,
      refreshFieldbook,
      refreshDisabled,
      refreshCountdown,
    };
  },
};
</script>
