<template lang="pug">
  n-modal(transform-origin="center")
    n-card(style="width: 600px" title="Create New Competitor" :bordered="false" size="huge" role="dialog" aria-modal="true")
      n-form(ref="competitorForm" :model="formState" :rules="rules" label-placement="top" size="large")
        n-form-item(label="Card Number" path="card_number")
          n-input-number(v-model:value="formState.card_number" placeholder="Card Number" style="width: 100%")
        n-form-item(label="Name" path="name")
          n-input(v-model:value="formState.name" placeholder="Name" style="width: 100%")
        n-form-item(label="External" path="external" )
          n-input-number(v-model:value="formState.external" placeholder="External" style="width: 100%")
        n-form-item(label="Metadata" path="metadata")
          n-input(v-model:value="formState.metadata" type="textarea" placeholder="Metadata")
        n-space
          n-space(justify="space-between")
            n-text Active
            n-switch(v-model:value="formState.active")
          n-space(justify="space-between")
            n-text Price locked
            n-switch(v-model:value="formState.locked")
        n-flex(justify="end" gap="8")
          n-button(type="primary" @click="handleSave") SAVE
          n-button(type="error" @click="handleCancel") CANCEL
</template>

<script>
import { ref, onMounted } from 'vue';
import axios_client from '../../utils/axios_client';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default {
  props: {
    event_id: {
      type: Number,
      required: true,
    },
    market_id: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:show', 'save'],
  setup(props, { emit }) {
    const competitorForm = ref(null);
    const formState = ref({
      card_number: null,
      name: '',
      external: null,
      metadata: JSON.stringify({ price: "MANNUAL" }),
      active: true,
      locked: false
    });

    const rules = {
      card_number: [
        { type: 'number', message: 'Card Number must be a number', trigger: 'blur' }
      ],
      name: [
        { required: true, message: 'Name is required', trigger: 'blur' }
      ],
      external: [
        { type: 'number', message: 'External must be a number', trigger: 'blur' }
      ]
    };

    const handleSave = () => {
      competitorForm.value.validate((errors) => {
        if (!errors) {
          emit('save', { ...formState.value });
          emit('update:show', false);
          resetFields();
        }
      });
    };

    const handleCancel = () => {
      emit('update:show', false);
      resetFields();
    };

    const resetFields = () => {
      formState.value.name = '';
      formState.value.external = null;
    };

    const fetchInitialCompetitorData = async () => {
      try {
        const response = await axios_client.get(`/events/${props.event_id}/markets/${props.market_id}/competitors/new`, {
          headers: { 'X-CSRF-Token': csrfToken }
        });
        const competitor = response.data;
        formState.value.card_number = competitor.card_number;
        formState.value.metadata = JSON.stringify(competitor.metadata);
      } catch (error) {
        console.error("Failed to fetch initial competitor.", error);
      }
    };

    onMounted(() => {
      fetchInitialCompetitorData();
    });

    return {
      competitorForm,
      formState,
      rules,
      handleSave,
      handleCancel
    };
  },
};
</script>
