<template lang="pug">
    n-flex(justify="space-between")
      n-h3 Dividends
      n-data-table(:columns="columns" :data="data" :pagination="pagination" @update:sorter="handleUpdateSorter")


</template>

<script>
import { ref, computed, watchEffect, h } from 'vue'
import { NTag } from 'naive-ui'

export default {
  props: {
    dividends: {
      type: Array,
      required: true,
    }
  },
  setup(props) {
    const sortStatesRef = ref([])
    const data = ref([]);
    const paginationRef = ref({ pageSize: 10 })

    watchEffect(() => {
      data.value = props.dividends?.map((item) => (
        {
          type: item.type,
          amount: item.amount
        }
      ))
    })

    const columnsRef = computed(() => [
      {
        title: 'Type',
        key: 'type',
        render(row) {
          return h(
            NTag,
            {
              style: {
                marginRight: '6px'
              },
              type: 'info',
              round: true,
              bordered: false
            },
            {
              default: () => row.type
            }
          )
        }
      },
      {
        title: 'Amount',
        key: 'amount',
      },
    ])

    function handleUpdateSorter(sorters) {
      sortStatesRef.value = [].concat(sorters)
    }

    return {
      columns: columnsRef,
      data,
      pagination: paginationRef,
      handleUpdateSorter
    }
  }

};
</script>