import './materialize';
import './admin/pending.js';
import naive from 'naive-ui';
import { createApp } from 'vue';
import PriceRuleForm from './admin/price_rule_form.vue';
import BetLimitForm from './admin/bet_limit_form.vue';

import KycForm from './kyc/kyc_form.vue';
import FieldBooks from './admin/field_books.vue';
import UsersSettings from './admin/user/users_settings.vue';
import BonusForm from './admin/bonus_form.vue';
import Market from './admin/market/market.vue';
import MarketManage from './admin/market/market_manage.vue';

const app = createApp({});

app.component('price-rule-form', PriceRuleForm);
app.component('bet-limit-form', BetLimitForm);
app.component('kyc-form', KycForm);
app.component('field-books-table', FieldBooks);
app.component('users-settings', UsersSettings);
app.component('bonus-form', BonusForm);
app.component('market', Market);
app.component('market-manage', MarketManage);
app.use(naive);

const vueBehaviorEl = document.querySelector('[data-behavior="vue"]');

if (vueBehaviorEl) {
  document.addEventListener('DOMContentLoaded', () => {
    app.mount('[data-behavior="vue"]');
  });
}
