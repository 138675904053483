<template lang="pug">
    n-flex(justify="space-between" style="gap:0")
      n-h3(align-text) Unsettled
      n-button-group(v-if="data.length > 0")
        n-button(size="small" type="error" @click="markAllAsLost" ghost) MARK ALL LOST
        n-button(size="small" type="info" @click="makeAllAsNonRunner" ghost) MARK ALL NON RUNNERS
      n-data-table(v-if="data.length > 0" :columns="columns" :data="data" :pagination="pagination" @update:sorter="handleUpdateSorter")
      n-space(v-else vertical style="margin: 10px" justify="center")
        n-alert(title="All Settled" type="success") All competitors have been settled. No unsettled competitors found.
</template>

<script>
import { ref, computed, h, onMounted, watchEffect } from 'vue'
import { NButton, NButtonGroup, NText } from 'naive-ui'
import axios_client from '../../../utils/axios_client';
import { useMessage } from 'naive-ui';


export default {
  props: {
    market_id: {
      type: Number,
      required: true,
    },
    unsettled: {
      type: Array,
      required: true,
    }
  },
  emits: ['refresh-data'],
  setup(props, { emit }) {
    const message = useMessage();
    const data = ref(props.unsettled);
    const sortStatesRef = ref([])
    const sortKeyMapOrderRef = computed(() =>
      sortStatesRef.value.reduce((result, { columnKey, order }) => {
        result[columnKey] = order
        return result
      }, {})
    )
    const paginationRef = ref({ pageSize: 15 })

    const columnsRef = computed(() => [
      {
        title: 'Card No/Name',
        key: 'name',
        sortOrder: sortKeyMapOrderRef.value.pos || false,
        sorter(rowA, rowB) {
          return rowA.pos - rowB.pos
        },
        render(row) {
          return h(
            NText,
            {},
            {
              default: () => `(${row.card_number ?? ""}) ${row.name}`
            }
          )
        }
      },
      {
        title: 'Latest Price',
        key: 'price',
        render(row) {
          return h(
            NText,
            {
              type: "success"
            },
            {
              default: () => row.latest_price.fractional
            }
          )
        }
      },
      {
        title: '',
        key: 'action',
        render(row) {
          return h(
            NButtonGroup,
            {},
            {
              default: () => [
                h(
                  NButton,
                  {
                    size: 'small',
                    type: 'error',
                    ghost: true,
                    onClick: () => { markAsLost(row.id) }
                  },
                  { default: () => 'LOST' }
                ),
                h(
                  NButton,
                  {
                    size: 'small',
                    type: 'info',
                    ghost: true,
                    onClick: () => { makeAsNonRunner(row.id) }
                  },
                  { default: () => 'NR' }
                )
              ]
            }
          )
        }
      },
    ])

    watchEffect(() => {
      data.value = [...props.unsettled];
    })

    function handleUpdateSorter(sorters) {
      sortStatesRef.value = [].concat(sorters)
    }

    const markAllAsLost = async () => {
      try {
        const response = await axios_client.get(`${props.market_id}/competitors/mark-all-as-lost`, {
          params: { ids: data.value.map(c => c.id) }
        });

        message.success(response.data.message);
        emit('refresh-data');
      } catch (error) {
        console.error(
          'Error in mark-all-as-lost:',
          error.response ? error.response.data : error.message,
        );
        message.error('Error in mark-all-as-lost');
      }
    }

    const makeAllAsNonRunner = async () => {
      try {
        const response = await axios_client.get(`${props.market_id}/make_all_unsettled_non_runners`);

        message.success(response.data.message);
        emit('refresh-data');
      } catch (error) {
        console.error(
          'Error in make_all_unsettled_non_runners:',
          error.response ? error.response.data : error.message,
        );
        message.error('Error in make_all_unsettled_non_runners');
      }
    }

    const markAsLost = async (id) => {
      try {
        const response = await axios_client.get(`${props.market_id}/competitors/${id}/mark_as_lost`);

        message.success(response.data.message);
        emit('refresh-data');
      } catch (error) {
        console.error(
          'Error in mark_as_lost:',
          error.response ? error.response.data : error.message,
        );
        message.error('Error in mark_as_lost');
      }
    }

    const makeAsNonRunner = async (id) => {
      try {
        const response = await axios_client.get(`${props.market_id}/competitors/${id}/mark_as_non_runner`);

        // message.success(response.data.message);
        message.success("Competitor is marked as non runner");
        emit('refresh-data');
      } catch (error) {
        console.error(
          'Error in make_as_non_runner:',
          error.response ? error.response.data : error.message,
        );
        message.error('Error in make_as_non_runner');
      }
    }

    return {
      columns: columnsRef,
      handleUpdateSorter,
      data,
      pagination: paginationRef,
      markAllAsLost,
      makeAllAsNonRunner,
    }
  }

};
</script>