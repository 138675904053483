<template lang="pug">
  n-config-provider
    n-message-provider
      n-dialog-provider
        div(v-if="!market")
          .card-panel
            h4.center
              i.fa.fa-spinner.fa-spin
              span &nbsp;
              span Loading Market

        n-layout(v-else style="height: 768px")
          n-layout(v-if="isMobile" position="static" style="width: 100%; padding: 16px")
            n-layout-header(style="text-align: center; margin-bottom: 10px;")
              n-h1 {{ market.name }}
            MarketDetails(:market="market")
            n-layout(content-style="padding: 0 0;")
              n-tabs(v-model:value="activeTab" type="line" animated)
                n-tab-pane(name="competitors" tab="Competitors")
                  Competitors(:competitors="market.competitors" :event_id="market.event_id" :market_id="market.id")
                n-tab-pane(name="results" tab="Results")
                  Results(:market="market")
                n-tab-pane(name="field_book" tab="Field Book")
                  FieldBook(:market_id="market.id")
                template(v-if="activeTab === 'competitors'" #suffix)
                  n-space(align="center")
                    n-space(align="baseline")
                      n-text Sort by
                      n-select(v-model:value="sortField" :options="sortOptions" size="small" style="width: 130px")
                    n-button(@click="setSortOrder" type="info" ghost size="small") {{ sortOrder === 'asc' ? '▲' : '▼' }}
          n-layout(position="absolute" has-sider v-else)
            n-layout-sider
              n-layout-header
                n-h1 {{ market.name }}
              MarketDetails(:market="market")
            n-layout(content-style="padding: 0 24px;")
              n-tabs(v-model:value="activeTab" type="line" animated)
                n-tab-pane(name="competitors" tab="Competitors")
                  Competitors(:competitors="market.competitors" :event_id="market.event_id" :market_id="market.id"
                    :sort_order="sortOrder" :sort_field="sortField")
                n-tab-pane(name="results" tab="Results")
                  Results(:market="market")
                n-tab-pane(name="field_book" tab="Field Book")
                  FieldBook(:market_id="market.id")
                template(v-if="activeTab === 'competitors'" #suffix)
                  n-space(align="center")
                    n-space(align="baseline")
                      n-text Sort by
                      n-select(v-model:value="sortField" :options="sortOptions" size="small" style="width: 130px")
                    n-button(@click="setSortOrder" type="info" ghost size="small") {{ sortOrder === 'asc' ? '▲' : '▼' }}
</template>

<script>
import { ref, computed, onMounted, onUnmounted, provide } from 'vue'
import axios_client from '../../utils/axios_client';
import MarketDetails from './market_details.vue'
import Competitors from './competitors.vue'
import Results from './results.vue'
import FieldBook from '../field_books.vue'

export default {
  components: {
    MarketDetails,
    Competitors,
    Results,
    FieldBook
  },
  props: {
    market_id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const market = ref(null);
    const path = "/market";
    const isEnabled = ref(true);
    const activeTab = ref("competitors");
    const sortField = ref("price");
    const sortOrder = ref("asc");

    const sortOptions = [
      { label: "Name", value: "name" },
      { label: "Card Number", value: "card_number" },
      { label: "Price", value: "price" },
    ];

    const screenWidth = ref(window.innerWidth)

    const updateWidth = () => {
      screenWidth.value = window.innerWidth
    }

    onMounted(() => window.addEventListener('resize', updateWidth))
    onUnmounted(() => window.removeEventListener('resize', updateWidth))

    const isMobile = computed(() => screenWidth.value < 768)

    const fetchMarketData = async () => {
      try {
        const response = await axios_client.get(`${props.market_id}.json`);

        if (response.data) {
          market.value = response.data;

        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching market:',
          error.response ? error.response.data : error.message,
        );
      }
    };

    onMounted(() => {
      fetchMarketData();
    });


    const handleSortSelection = (key) => {
      sortField.value = key;
    };

    const setSortOrder = () => {
      sortOrder.value = sortOrder.value === 'asc' ? 'desc' : 'asc';
    };

    function switchTab(tabName) {
      activeTab.value = tabName
    }

    provide('switchTab', switchTab)

    return {
      isMobile,
      market,
      path,
      activeTab,
      sortField,
      sortOrder,
      sortOptions,
      handleSortSelection,
      setSortOrder,
    };
  }

};
</script>
