<template lang="pug">
  n-space(vertical style="padding: 10px; border: solid 2px #f0f0f0; border-radius:10px; gap: 0" )
    n-space(justify="space-between" @click="show = !show")
      n-space(vertical)
        n-text(type="info" style="font-size: 20px;") ({{comp.card_number}})  {{comp.name}}
        n-space(align="right")
          n-space(align="left")
            n-tag(v-if="comp.active" :bordered="false" type="success" round size="small") Active
            n-tag(v-else :bordered="false" round size="small") Inactive
            n-tag(v-if="comp.locked" :bordered="false" type="error" round size="small") Price Locked
            n-tag(v-if="comp.locked_until_shorter" :bordered="false" type="error" round size="small") Locked until shorter
      n-space(align="center")
        n-space(v-if="comp.latest_price.permissions.new" style="text-align: center" align="center")
          n-space(justify="space-between" v-if="comp.permissions['toggle_lock']")
            n-text Locked
            n-switch(v-model:value="toggles.locked" @update:value="performToggleLock")
          n-space(justify="space-between" v-if="comp.permissions['toggle_locked_until_shorter']")
            n-text Lock until shorter
            n-switch(v-model:value="toggles.locked_until_shorter" @update:value="performToggleLockUntilShorter")
          n-input(v-model:value="newPrice" style="max-width: 150px;" ref="priceRef"
            placeholder="Enter New Price" :status="inputStatus" @keyup.enter="savePrice" @input="validateFractionalPrice" @click.stop)
        n-space(style="font-size: 20px;")
          div(v-if="comp.non_runner")
            n-tag(:bordered="false" round) NR
          div(v-else)
            n-text(v-if="comp.market.sp_only" type="success" round) SP Only
            n-text(v-else type="success") {{comp.price_display}} ({{comp.latest_price.type}})

    n-collapse-transition(:show="show")
      n-space(vertical)
        n-divider(style="padding:0; margin: 0; margin-top:10px")
        n-space(justify="space-between" align="center")
          n-space
            n-text ID {{comp.id}}
            n-text Created at {{comp.created_at}}
            n-text Updated at {{comp.updated_at}}
        n-divider(style="padding:0; margin: 0; margin-top:10px")
        n-space(justify="space-between" align="center")
          n-space
          n-space
            n-button(@click="markAsLost") Mark as lost
            n-button(type="error" @click="handleDelete") Delete
            n-button(type="warning" @click="handleRemoveResults") Remove results


        n-divider(style="padding:0; margin: 0")
        n-space(align="center" justify="space-between")
          n-space
            n-space(vertical)
              n-text Card Number
              n-input(v-model:value="competitorDetail.card_number" type="text" placeholder="Card Number")
            n-space(vertical)
              n-text Name
              n-input(v-model:value="competitorDetail.name" type="text" placeholder="Name")
            n-space(vertical)
              n-text External
              n-input-number(v-model:value="competitorDetail.external_id" placeholder="External")
          n-space(align="center")
            n-space(justify="space-between")
              n-text Active
              n-switch(v-model:value="competitorDetail.active")
            n-space(justify="space-between")
              n-text Price Locked
              n-switch(v-model:value="competitorDetail.locked")
        n-space(vertical)
          n-text Metadata
          n-input-group
            n-input(v-model:value="competitorDetail.metadata" type="textarea" placeholder="Metadata" :style="{ width:'100%' }")
        n-input-group
          n-button(type="primary" :style="{ width:'100%' }" @click="saveCompetitor") Save

</template>

<script>
import { ref, reactive, nextTick, computed, onMounted } from 'vue'
import axios_client from '../../utils/axios_client';
import { useMessage, useDialog } from 'naive-ui';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default {
  props: {
    competitor: {
      type: Object,
      reuqired: true,
    },
  },
  emits: ['refresh'],
  setup(props, { emit }) {
    const message = useMessage();
    const dialog = useDialog();
    const newPrice = ref(null);
    const activeKey = ref([]);
    const show = ref(false);
    const comp = ref(props.competitor);
    const cardNumber = ref(props.competitor.card_number);
    const priceRef = ref(null);
    const competitorDetail = reactive({
      card_number: props.competitor.card_number,
      name: props.competitor.name,
      external_id: props.competitor.external_id,
      metadata: JSON.stringify(props.competitor.metadata),
      active: props.competitor.active,
      locked: props.competitor.locked,
    })

    const toggles = ref({
      locked: props.competitor.locked,
      locked_until_shorter: props.competitor.locked_until_shorter,
      non_runner: props.competitor.non_runner
    })
    const eventId = props.competitor.market.event_id;
    const marketId = props.competitor.market.id;
    const competitorId = props.competitor.id;

    const errorMessage = ref("");

    const fractionalRegex = /^\d+\/\d+(\.\d+)?$/;

    const inputStatus = computed(() => (errorMessage.value ? "error" : "success"));

    const savePrice = async () => {
      if (!newPrice.value) {
        message.warning("Please enter a valid price!");
        return;
      }
      if (errorMessage.value) {
        message.error(errorMessage.value);
        return;
      }
      try {
        const response = await axios_client.post(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}/prices.json`,
          {
            price:
            {
              competitor_id: competitorId,
              fractional: newPrice.value
            }
          },
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const updatedPrice = response.data;
        comp.value.latest_price = updatedPrice;
        comp.value.price_display = updatedPrice.fractional;

        message.success("Price updated successfully!");
        newPrice.value = null;
        emit('refresh');
      } catch (error) {
        message.error("Failed to update price.");
      }
    };

    const handleUpdateCardnumber = () => {
      updateCardNumber();

      nextTick(() => {
        if (priceRef.value) {
          priceRef.value.focus();
        }
      });
    }

    const updateCardNumber = async () => {
      try {
        const response = await axios_client.patch(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}/update_card_number`,
          {
            card_number: cardNumber.value
          },
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const result = response.data;
        if (result?.success) {
          cardNumber.value = result.card_number;
          message.success("Card Number is updated successfully!");

        } else {
          message.error("Card Number is not updated! -" + result?.errors);
        }
      } catch (error) {
        message.error("Failed to update price.");
      }

    };


    const saveCompetitor = async () => {
      try {
        const response = await axios_client.patch(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}`,
          {
            competitor: competitorDetail,
          },
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const competitor = response.data;
        comp.value.name = competitor.name;
        comp.value.card_number = competitor.card_number;
        comp.value.active = competitor.active;
        comp.value.locked = competitor.locked;

        message.success("Competitor updated successfully!");
        newPrice.value = null;
        emit('refresh');
      } catch (error) {
        console.log(error);
        message.error("Failed to update competitor.");
      }
    };

    const handleDelete = () => {
      dialog.warning({
        title: 'Confirm delete',
        content: 'Are you sure want to delete the competitor?',
        positiveText: 'Yes',
        negativeText: 'No',
        draggable: true,
        onPositiveClick: () => {
          performDelete();
        },
      })
    }

    const handleRemoveResults = () => {
      dialog.warning({
        title: 'Are you sure want to remove the results?',
        content: 'This will delete all results created for this competitor. If the scraper is running it will recreate the result again.',
        positiveText: 'Yes',
        negativeText: 'No',
        draggable: true,
        onPositiveClick: () => {
          performRemoveResults();
        },
      })
    }

    const performDelete = async () => {
      try {
        const response = await axios_client.delete(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        if (response.data) {
          message.success("Competitor deleted successfully!");
          emit('refresh');
        }
        else
          message.error("Failed to delete competitor.");

      } catch (error) {
        message.error("Failed to delete competitor.");
      }
    }

    const performToggleLockUntilShorter = async () => {
      try {
        const response = await axios_client.get(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}/toggle_locked_until_shorter`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const result = response.data;
        comp.value.locked_until_shorter = toggles.value.locked_until_shorter = result;
        message.success(`${comp.value.name} is now ${result ? 'locked to until price is shorter' : 'unlocked'}`);
      } catch (error) {
        message.error("Failed to update lock until shorter.");
      }
    }

    const performToggleLock = async () => {
      try {
        const response = await axios_client.get(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}/toggle_lock`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const result = response.data;
        comp.value.locked = toggles.value.locked = result;
        if (result)
          message.success(`${comp.value.name} is locked to ${comp.value.latest_price.fractional} (${comp.value.latest_price.decimal})`);
        else
          message.success(`${comp.value.name} is now unlocked`);
      } catch (error) {
        message.error("Failed to update locked.");
      }
    }

    const performMarkNR = async () => {
      try {
        const response = await axios_client.get(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}/mark_as_non_runner`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const result = response.data;
        comp.value.non_runner = toggles.value.non_runner = result.non_runner;
        comp.value.latest_price = result.latest_price;
        if (comp.value.non_runner)
          message.success(`${comp.value.name} is marked as non runner`);
        else
          message.success(`${comp.value.name} is now runner`);
      } catch (error) {
        message.error("Failed to update non runner.");
      }
    }

    const markAsLost = async () => {
      try {
        const response = await axios_client.get(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}/mark_as_lost`);

        message.success(response.data.message);
      } catch (error) {
        console.error(
          'Error in mark_as_lost:',
          error.response ? error.response.data : error.message,
        );
        message.error('Error in mark_as_lost');
      }
    }

    const performRemoveResults = async () => {
      try {
        const response = await axios_client.get(`/events/${eventId}/markets/${marketId}/competitors/${competitorId}/remove_results`);

        message.success(response.data.message);
      } catch (error) {
        console.error(
          'Error in remove_results:',
          error.response ? error.response.data : error.message,
        );
        message.error('Error in remove_results');
      }
    }

    const validateFractionalPrice = () => {
      if (!newPrice.value.match(fractionalRegex)) {
        errorMessage.value = "Invalid format! Use fractional format like : 2/3";
      } else {
        errorMessage.value = "";
      }
    };

    return {
      activeKey,
      show,
      savePrice,
      saveCompetitor,
      newPrice,
      comp,
      competitorDetail,
      inputStatus,
      validateFractionalPrice,
      errorMessage,
      toggles,
      performToggleLockUntilShorter,
      performToggleLock,
      performMarkNR,
      markAsLost,
      handleDelete,
      handleRemoveResults,
      cardNumber,
      updateCardNumber,
      handleUpdateCardnumber,
      priceRef
    }
  }

};
</script>
