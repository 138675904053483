<template lang="pug">
    n-flex(justify="space-between")
      n-h3 Lost
      n-data-table(:columns="columns" :data="data" :pagination="pagination" @update:sorter="handleUpdateSorter")

</template>

<script>
import { ref, computed, onMounted, h, watchEffect } from 'vue'
import { NText, NButton, NTag, useDialog, useMessage } from 'naive-ui'
import axios_client from '../../../utils/axios_client';

export default {
  props: {
    lost: {
      type: Array,
      required: true,
    },
    market: {
      type: Object,
      required: true,
    }
  },
  emits: ['refresh-data'],
  setup(props, { emit }) {
    const dialog = useDialog();
    const message = useMessage();
    const data = ref([]);
    const sortStatesRef = ref([])

    const paginationRef = ref({ pageSize: 5 })

    watchEffect(() => {
      data.value = [...props.lost];
    })

    const columnsRef = computed(() => [
      {
        title: 'Card No/Name',
        key: 'name',
        render(row) {
          return h(
            NText,
            {},
            {
              default: () => //`(${row.competitor.card_number}) ${row.competitor.name}`
                h('div', [
                  h(NText, { strong: true }, { default: () => `(${row.competitor.card_number}) ${row.competitor.name}` }),
                  row.fav_label ? h(NTag, { type: 'info', style: { marginLeft: '8px' }, round: 'true' }, { default: () => row.fav_label }) : null
                ])
            }
          )
        }
      },
      {
        title: 'Price',
        key: 'price',
        render(row) {
          return h(
            NText,
            {
              type: "success"
            },
            {
              default: () => row.fractional
            }
          )
        }
      },
      {
        title: '',
        key: 'delete',
        render(row) {
          return h(
            NButton,
            {
              size: 'tiny',
              type: 'warning',
              ghost: true,
              onClick: () => {
                dialog.warning({
                  title: 'Confirm delete?',
                  content: 'Are you sure want to remove the result?',
                  positiveText: 'Yes',
                  negativeText: 'No',
                  draggable: true,
                  onPositiveClick: () => {
                    performDelete(row.competitor.id);
                  },
                })
              }
            },
            { default: () => h('i', { class: 'fas fa-trash' }) }
          )
        }
      }
    ])

    function handleUpdateSorter(sorters) {
      sortStatesRef.value = [].concat(sorters)
    }

    const performDelete = async (id) => {
      try {
        const response = await axios_client.get(`/events/${props.market.event_id}/markets/${props.market.id}/competitors/${id}/remove_results`);
        message.success(response.data.message);
        emit('refresh-data');

      } catch (error) {
        console.error(error);
        message.error("Error while deleting result.");
      }
    }

    return {
      columns: columnsRef,
      data,
      pagination: paginationRef,
      handleUpdateSorter,

    }
  }

};
</script>