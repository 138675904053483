<template lang="pug">
  n-flex
    n-flex(vertical style="padding: 10px 5px; border: solid 2px #f0f0f0; border-radius:10px;" :style="{width: firstWidth}")
      ResultsTable(:market="market" :results="results" @refresh-data="refreshAllData")
    n-flex(vertical style="padding: 10px 5px; border: solid 2px #f0f0f0; border-radius:10px;" :style="{width: firstWidth}")
      UnsettledTable(:market_id="market.id" :unsettled="unsettled" @refresh-data="refreshAllData")
    n-flex(vertical :style="{width: secondWidth}")
      n-flex(vertical style="padding: 10px 5px; border: solid 2px #f0f0f0; border-radius:10px;" )
        DividendsTable(:dividends="market.dividends")
      n-flex(vertical style="padding: 10px 5px; border: solid 2px #f0f0f0; border-radius:10px;" )
        LostTable(:lost="lost" :market="market" @refresh-data="refreshAllData")
      n-flex(vertical style="padding: 10px 5px; border: solid 2px #f0f0f0; border-radius:10px;" )
        NonRunnersTable(:nonRunners="nonRunners" @refresh-data="refreshAllData")
      n-flex(vertical style="padding: 10px 5px; border: solid 2px #f0f0f0; border-radius:10px;" )
        DeductionsTable(:deductions="deductions")

</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import ResultsTable from './result/results_table.vue'
import UnsettledTable from './result/unsettled_table.vue'
import DividendsTable from './result/dividends_table.vue'
import LostTable from './result/lost_table.vue'
import NonRunnersTable from './result/non_runners_table.vue'
import DeductionsTable from './result/deductions_table.vue'
import axios_client from '../../utils/axios_client'

export default {
  props: {
    market: {
      type: Object,
      reuiqred: true
    }
  },
  components: {
    ResultsTable,
    UnsettledTable,
    DividendsTable,
    LostTable,
    NonRunnersTable,
    DeductionsTable
  },
  setup(props) {
    const results = ref([]);
    const unsettled = ref([]);
    const lost = ref([]);
    const deductions = ref([]);
    const nonRunners = ref([]);
    const screenWidth = ref(window.innerWidth)

    const updateWidth = () => {
      screenWidth.value = window.innerWidth
    }

    onMounted(() => window.addEventListener('resize', updateWidth))
    onUnmounted(() => window.removeEventListener('resize', updateWidth))

    const firstWidth = computed(() => {
      if (screenWidth.value < 1024) return "100%";
      if (screenWidth.value < 1280) return "49%";
      return "32%"
    })

    const secondWidth = computed(() => {
      if (screenWidth.value < 1024) return "100%";
      if (screenWidth.value < 1280) return "100%";
      return "32%"
    })

    const fetchResults = async () => {
      try {
        const response = await axios_client.get(`${props.market.id}/results.json`);

        if (response.data) {
          results.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching results:',
          error.response ? error.response.data : error.message,
        );
      }
    };

    const fetchUnsettledComps = async () => {
      try {
        const response = await axios_client.get(`${props.market.id}/unsettled_competitors.json`);

        if (response.data) {
          unsettled.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching unsettled competitors:',
          error.response ? error.response.data : error.message,
        );
      }
    }

    const fetchLostCompetitors = async () => {
      try {
        const response = await axios_client.get(`${props.market.id}/results/lost`)

        if (response.data) {
          lost.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching lost competitors:',
          error.response ? error.response.data : error.message,
        );
      }
    }

    const fetchDeductions = async () => {
      try {
        const response = await axios_client.get(`${props.market.id}/deductions`)

        if (response.data) {
          deductions.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching deductions',
          error.response ? error.response.data : error.message,
        );
      }
    }


    const fetchNonRunners = async () => {
      try {
        const response = await axios_client.get(`${props.market.id}/non_runner_competitors`)

        if (response.data) {
          nonRunners.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching non runners',
          error.response ? error.response.data : error.message,
        );
      }
    }


    const refreshAllData = () => {
      fetchResults();
      fetchUnsettledComps();
      fetchLostCompetitors();
      fetchDeductions();
      fetchNonRunners();
    };

    onMounted(() => {
      fetchResults();
      fetchUnsettledComps();
      fetchLostCompetitors();
      fetchDeductions();
      fetchNonRunners();
    });

    return {
      firstWidth,
      secondWidth,
      results,
      refreshAllData,
      unsettled,
      lost,
      deductions,
      nonRunners
    }
  }

};
</script>