<template lang="pug">
  n-flex(vertical style="margin-bottom: 10px; width:100%")
    n-h1(v-if="is_edit") Edit Market
    n-h1(v-else) Create New Market
    n-form(ref="marketForm" :model="market" :rules="rules" label-placement="top" size="large")
      n-flex(vertical style="width: 100%; padding: 16px; border: 2px solid #f0f0f0; border-radius: 10px;")
        n-flex
          n-form-item(label="Name" path="name" style="width: 100%;")
            n-input(v-model:value="market.name" style="width: 100%;")
        n-flex
          n-form-item(label="Scheduled Off Date/Time" path="scheduled_off_time")
            n-input-group(style="width: 100%;")
              n-date-picker(v-model:value="scheduled_off_time_date"  size="small")
              n-time-picker(v-model:value="scheduled_off_time_time" size="small")
          n-form-item(label="No More Bets Off Date/Time" path="no_more_bets_time")
            n-input-group
              n-date-picker(v-model:value="no_more_bets_time_date" size="small" )
              n-time-picker(v-model:value="no_more_bets_time_time" size="small")
            n-flex
              n-button(type="primary" size="small" @click="setToNow") SET TO NOW
              n-button(type="error" size="small" @click="handleClear") CLEAR
        n-flex
          n-form-item(label="Places" path="places")
            n-input-number(v-model:value="market.places" style="max-width: 150px")
          n-form-item(label="Place Fraction" path="place_fraction")
            n-input-number(v-model:value="market.place_fraction" style="max-width: 150px")
        n-flex
          n-form-item(label="Main Reference" path="fsb_ref")
            n-input(v-model:value="market.fsb_ref" style="max-width: 310px")
          n-form-item(label="Event Reference" path="fsb_event_id")
            n-input-number(v-model:value="market.fsb_event_id" style="max-width: 150px")
          n-form-item(label="Market Reference" path="fsb_market_id")
            n-input-number(v-model:value="market.fsb_market_id" style="max-width: 150px")
        n-space(justify="space-between")
          n-text Lock these values and do not let the feed over write them
          n-switch(v-model:value="market.locked")
      n-button(type="primary" style="width: 100%; margin-top: 10px" @click="submitForm") {{ is_edit ? 'SAVE MARKET' : 'CREATE MARKET' }}
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch, watchEffect } from 'vue'
import moment from 'moment';

export default {
  props: {
    is_edit: {
      type: String,
      required: true,
    },
    oldMarket: {
      type: Object,
      required: false,
    }
  },
  emits: ['update:modelValue', 'save', 'update'],
  setup(props, { emit }) {
    const marketForm = ref(null);
    const market = ref({
      event_id: null,
      name: "",
      places: 1,
      place_fraction: 4,
      fsb_ref: "",
      fsb_event_id: null,
      fsb_market_id: null,
      locked: false,
    });

    const rules = {
      name: [
        { required: true, message: 'Market name is required', trigger: ['blur', 'input'] }
      ],
      scheduled_off_time: [
        {
          required: true,
          validator(rule, value) {
            if (!scheduled_off_time_date.value || !scheduled_off_time_time.value) {
              return new Error('Scheduled off time date and time are required');
            }
            return true;
          },
          trigger: ['blur', 'change']
        }
      ]
    };

    const no_more_bets_time_date = ref(null);
    const no_more_bets_time_time = ref(null);
    const scheduled_off_time_date = ref(null);
    const scheduled_off_time_time = ref(null);

    const screenWidth = ref(window.innerWidth)

    const updateWidth = () => {
      screenWidth.value = window.innerWidth
    }

    watchEffect(() => {
      if (props.oldMarket) {
        market.value = { ...props.oldMarket };
        if (props.oldMarket.scheduled_off_time) {
          const scheduled_off_time = new Date(props.oldMarket.scheduled_off_time);
          scheduled_off_time_date.value = scheduled_off_time.getTime();
          scheduled_off_time_time.value = scheduled_off_time.getTime();
        }
        if (props.oldMarket.no_more_bets_time) {
          const no_more_bets_time = new Date(props.oldMarket.no_more_bets_time);
          no_more_bets_time_date.value = no_more_bets_time.getTime();
          no_more_bets_time_time.value = no_more_bets_time.getTime();
        }
      }
    });

    onMounted(() => {
      const pathSegments = window.location.pathname.split("/");
      market.value.event_id = Number.parseInt(pathSegments[2]);

      window.addEventListener('resize', updateWidth);
    })
    onUnmounted(() => window.removeEventListener('resize', updateWidth))

    const isMobile = computed(() => screenWidth.value < 768)


    watch(market, (newValue) => {
      emit("update:modelValue", newValue);
    }, { deep: true });

    const setToNow = () => {
      const now = new Date();
      no_more_bets_time_date.value = now.getTime();
      no_more_bets_time_time.value = now.getTime();
    }

    const handleClear = () => {
      no_more_bets_time_date.value = null;
      no_more_bets_time_time.value = null;
    }

    const submitForm = () => {
      marketForm.value.validate((errors) => {
        if (!errors) {
          const date1 = moment(no_more_bets_time_date.value).format('MMM DD, yyyy');
          const time1 = moment(no_more_bets_time_time.value).format('hh:mm A');
          const date2 = moment(scheduled_off_time_date.value).format('MMM DD, yyyy');
          const time2 = moment(scheduled_off_time_time.value).format('hh:mm A');

          const data = { ...market.value, no_more_bets_time: `${date1} ${time1}`, scheduled_off_time: `${date2} ${time2}` };

          if (props.is_edit === 'true') {
            emit('update', data);
          } else {
            emit('save', data);
          }
        }
      });
    };


    return {
      isMobile,
      is_edit: props.is_edit === 'true',
      market,
      rules,
      submitForm,
      setToNow,
      handleClear,
      no_more_bets_time_date,
      no_more_bets_time_time,
      scheduled_off_time_date,
      scheduled_off_time_time,
      marketForm
    };
  }

};
</script>
