<template lang="pug">
  n-space(vertical style="display: flex; gap: 10px; padding: 12px; border: 2px solid #f0f0f0; border-radius: 10px;")
    n-space(justify="space-between")
      n-text Scheduled off
      n-text(type="success") {{formatTime(market.scheduled_off_time)}}
    n-space(justify="space-between")
      n-text EW terms
      n-text(type="success") {{market.place_betting_terms}}
    n-space
    n-space(v-if="market.permissions.toggle_no_more_bets" justify="space-between")
      n-text No more bets
      n-switch(:value="market.no_more_bets_time !== null" @update:value="handleToggleNoMoreBets")
      n-text(v-if="market.no_more_bets_time !== null" type="success") {{formatTime(market.no_more_bets_time)}}
    n-space(v-if="market.permissions.toggle_sp_only" justify="space-between" )
      n-text SP only
      n-switch(:value="market.sp_only" @update:value="handleToggleSPOnly")
    n-space(v-if="market.permissions.toggle_active" justify="space-between")
      n-text Active
      n-switch(:value="market.active" @update:value="handleToggleActive")
    n-space(v-if="market.permissions.hold_result" justify="space-between")
      n-text Hold result
      n-switch(:value="market.hold_result" @update:value="handleToggleHoldResult")
    n-space(v-if="market.permissions.toggle_show_on_screen" justify="space-between")
      n-text Show on screen
      n-switch(:value="market.show_on_screen" @update:value="handleToggleShowOnScreen")
    n-space
    n-space(justify="space-between")
      n-a(@click="goToCompetitors") Competitors
      n-text {{market.competitors.length}}
    n-space(justify="space-between")
      n-a(@click="goToResults") Results
      n-text {{market.results.length}}
    n-space
      n-a(@click="goToFieldbook") Field Book
  n-space(vertical style="padding: 10px")
    n-a(:href="bettingAppLink")
      n-button(type="primary" style="width: 100%") VIEW IN BETTING APP
    n-a(:href="editLink")
      n-button(v-if="market.permissions.edit" type="info" style="width: 100%") EDIT
    n-button(v-if="market.permissions.destroy" type="error" style="width: 100%" @click="handleDelete") DELETE


</template>

<script>
import moment from 'moment-timezone';
import axios_client from '../../utils/axios_client';
import { ref, watchEffect, computed, inject } from 'vue';
import { useDialog, useMessage } from 'naive-ui';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default {
  props: {
    market: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const message = useMessage();
    const dialog = useDialog();
    const market = ref(props.market);
    watchEffect(() => {
      market.value = { ...props.market };
    });
    const switchTab = inject('switchTab')

    const bettingAppLink = computed(() => `/#/cart/categories/${market.value.category_id}/events/${market.value.event_id}/markets/${market.value.id}`)
    const editLink = computed(() => `/events/${market.value.event_id}/markets/${market.value.id}/edit`)

    const formatTime = (time) => {
      return time ? moment(time).tz("Europe/Dublin").format("D MMM YYYY HH:mm:ss") : "";
    };

    const toggleNoMoreBets = async (marketId) => {
      try {
        const response = await axios_client.get(`${marketId}/toggle_no_more_bets`);
        return response.data;
      } catch (error) {
        console.error("Error toggling no more bets:", error);
        return 'error';
      }
    };

    const toggleActive = async (marketId) => {
      try {
        const response = await axios_client.get(`${marketId}/toggle_active`);
        return response.data;
      } catch (error) {
        console.error("Error toggling active:", error);
        return null;
      }
    };

    const toggleSPOnly = async (marketId) => {
      try {
        const response = await axios_client.get(`${marketId}/toggle_sp_only`);
        return response.data;
      } catch (error) {
        console.error("Error toggling sp only:", error);
        return null;
      }
    };

    const toggleHoldResult = async (marketId) => {
      try {
        const response = await axios_client.get(`${marketId}/hold_result`);
        return response.data;
      } catch (error) {
        console.error("Error toggling Hold Result:", error);
        return null;
      }
    };

    const toggleShowOnScreen = async (marketId) => {
      try {
        const response = await axios_client.get(`${marketId}/toggle_show_on_screen`);
        return response.data;
      } catch (error) {
        console.error("Error toggling Show on Screen:", error);
        return null;
      }
    };

    const handleToggleNoMoreBets = async () => {
      if (!market.value.id) return;
      const time = await toggleNoMoreBets(market.value.id);
      if (time !== 'error') {
        market.value.no_more_bets_time = time;
        message.success(`Market no more bets time set to ${formatTime(time) || "None"}`);
      } else {
        message.error("Failed to update no more bets time.");
      }
    };

    const handleToggleSPOnly = async () => {
      if (!market.value.id) return;
      const result = await toggleSPOnly(market.value.id);
      if (result !== null) {
        market.value.sp_only = result;
        message.success(`Market ${market.value.name} has been ${result ? "set to SP only" : "set to allow Prices"}`);
      } else {
        message.error("Failed to update SP only.");
      }
    };

    const handleToggleActive = async () => {
      if (!market.value.id) return;
      const result = await toggleActive(market.value.id);
      if (result !== null) {
        market.value.active = result;
        message.success(`Market ${market.value.name} has been ${result ? "Activated" : "Deactivated"}`);
      } else {
        message.error("Failed to update Active");
      }
    };

    const handleToggleHoldResult = async () => {
      if (!market.value.id) return;
      const result = await toggleHoldResult(market.value.id);
      if (result !== null) {
        market.value.hold_result = result;
        message.success(`${result ? "Results held!" : "Hold on results released"}`);
      } else {
        message.error("Failed to update Hold Result");
      }
    };

    const handleToggleShowOnScreen = async () => {
      if (!market.value.id) return;
      const result = await toggleShowOnScreen(market.value.id);
      if (result !== null) {
        market.value.show_on_screen = result;
        message.success(`Market ${market.value.name} has been ${result ? "added to" : "removed from"} all in one screens`);
      } else {
        message.error("Failed to update Show on Screen");
      }
    };

    const performDelete = async () => {
      try {
        const response = await axios_client.delete(`${market.value.id}`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        if (response.data) {
          message.success("Market deleted successfully!");
          setTimeout(() => {
            window.location.href = `/events/${market.value.event_id}/markets`;
          }, 500);
        }
        else
          message.error("Failed to delete market.");

      } catch (error) {
        message.error("Error while deleting market.");
      }
    }

    const handleDelete = () => {
      dialog.warning({
        title: 'Confirm delete',
        content: 'Are you sure want to delete the market?',
        positiveText: 'Yes',
        negativeText: 'No',
        draggable: true,
        onPositiveClick: () => {
          performDelete();
        },
      })
    }

    function goToResults() {
      switchTab('results')
    }

    function goToCompetitors() {
      switchTab('competitors')
    }

    function goToFieldbook() {
      switchTab('field_book')
    }

    return {
      formatTime,
      market,
      handleToggleNoMoreBets,
      handleToggleSPOnly,
      handleToggleActive,
      handleToggleHoldResult,
      handleToggleShowOnScreen,
      handleDelete,
      bettingAppLink,
      editLink,
      goToResults,
      goToCompetitors,
      goToFieldbook
    };
  }

};
</script>