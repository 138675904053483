<template lang="pug">
  n-message-provider
    n-layout(style="height: 768px")
      n-layout(v-if="isMobile" position="static" style="width: 100%; padding: 16px")
        MarketForm(v-model="market" :is_edit="is_edit" :oldMarket="fetchedMarket" @save="createMarket" @update="updateMarket")
        n-layout(content-style="padding: 0 0;")
          CompetitorForm(v-model="comps" :oldComps="fetchedMarket?.competitors" :is_edit="is_edit")
      n-layout(position="absolute" has-sider v-else) 
        n-layout-sider(width="650")
          MarketForm(v-model="market" :is_edit="is_edit" :oldMarket="fetchedMarket" @save="createMarket" @update="updateMarket")
        n-layout(content-style="padding: 0 24px;")
          CompetitorForm(v-model="comps" :oldComps="fetchedMarket?.competitors" :is_edit="is_edit")

</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import MarketForm from './market_form.vue'
import CompetitorForm from './competitor_form.vue'
import axios_client from '../../utils/axios_client'

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default {
  components: {
    MarketForm,
    CompetitorForm,
  },
  props: {
    is_edit: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const market = ref(null);
    const comps = ref([]);
    const fetchedMarket = ref(null);
    const market_id = ref('');
    const event_id = ref(null);

    const screenWidth = ref(window.innerWidth)

    const updateWidth = () => {
      screenWidth.value = window.innerWidth
    }

    onMounted(() => {
      const pathSegments = window.location.pathname.split("/");
      event_id.value = pathSegments[2];
      market_id.value = pathSegments[4];
      window.addEventListener('resize', updateWidth)

      if (props.is_edit === 'true') fetchMarket();
    })
    onUnmounted(() => window.removeEventListener('resize', updateWidth))

    const isMobile = computed(() => screenWidth.value < 768)


    const createMarket = async (data) => {
      try {
        const compsObject = comps.value.reduce((acc, item, index) => {
          acc[index] = item;
          return acc;
        }, {});
        const response = await axios_client.post(`/events/${event_id.value}/markets`,
          {
            market: { ...data, competitors_attributes: compsObject }
          },
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        console.log(response.data);
        setTimeout(() => {
          window.location.href = `/events/${event_id.value}/markets`;
        }, 500);
        // message.success(response.data);
      } catch (error) {
        console.error(error);
        // message.error("Failed to add competitor.");
      }
    };

    const updateMarket = async (data) => {
      try {
        const hash = comps.value.reduce((acc, item, index) => {
          acc[index] = item;
          return acc;
        }, {});

        const response = await axios_client.patch(`/events/${event_id.value}/markets/${market_id.value}`,
          {
            market: {
              ...data, "competitors_attributes": hash
            }
          },
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        console.log(response.data);
        setTimeout(() => {
          window.location.href = `/events/${event_id.value}/markets`;
        }, 500);
        // message.success(response.data);
      } catch (error) {
        console.error(error);
        // message.error("Failed to add competitor.");
      }
    };

    const fetchMarket = async () => {
      try {
        const response = await axios_client.get(`/events/${event_id.value}/markets/${market_id.value}.json`);

        if (response.data) {
          fetchedMarket.value = response.data;
        } else {
          console.error('Unexpected response structure:', response);
        }
      } catch (error) {
        console.error(
          'Error fetching market:',
          error.response ? error.response.data : error.message,
        );
      }
    };

    return {
      isMobile,
      market,
      comps,
      fetchedMarket,
      createMarket,
      updateMarket
    };
  }

};
</script>