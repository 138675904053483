<template lang="pug">
  n-space(vertical)
    n-grid(:key="gridKey" :cols="gridColumns" x-gap="12" y-gap="12")
      n-gi(v-for="(competitor, index) in comps" :key="index")
        Competitor(:competitor="competitor" @refresh="fetchCompetitors")
    n-button(type="primary" :style="{ width: '100%' }" @click="showNewModal = true") Add New Competitor
  CompetitorModal(v-model:show="showNewModal" @save="addNewCompetitor" :event_id="event_id" :market_id="market_id")
</template>

<script>
import { ref, watchEffect, computed, onMounted, onUnmounted, watch } from 'vue'
import Competitor from './competitor.vue'
import CompetitorModal from './competitor_modal.vue'
import axios_client from '../../utils/axios_client'
import { useMessage } from 'naive-ui'

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default {
  components: {
    Competitor,
    CompetitorModal
  },
  props: {
    competitors: {
      type: Array,
      required: true,
    },
    event_id: {
      type: Number,
      required: true,
    },
    market_id: {
      type: Number,
      required: true,
    },
    sort_order: {
      type: String,
      required: true,
    },
    sort_field: {
      type: String,
      required: true,
    }
  },
  setup(props, { emit }) {
    const showNewModal = ref(false)
    const message = useMessage();
    const comps = ref([]);
    const screenWidth = ref(window.innerWidth)
    const gridKey = ref(0);
    const updateWidth = () => {
      screenWidth.value = window.innerWidth
    }

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
      comps.value = props.competitors.map(c => ({ ...c, price: parseFloat(c.latest_price?.decimal) }));
      comps.value = sortCompetitors();
    }
    )
    onUnmounted(() => window.removeEventListener('resize', updateWidth))

    const gridColumns = computed(() => (screenWidth.value > 1280 ? 2 : 1));

    const sortCompetitors = () => {
      if (!props.sort_field) return comps.value;

      return [...comps.value].sort((a, b) => {
        const fieldA = a[props.sort_field];
        const fieldB = b[props.sort_field];

        if (typeof fieldA === "string" && typeof fieldB === "string") {
          return props.sort_order === "asc"
            ? fieldA.localeCompare(fieldB)
            : fieldB.localeCompare(fieldA);
        } else {
          return props.sort_order === "asc"
            ? fieldA - fieldB
            : fieldB - fieldA;
        }
      });
    };

    watch([() => props.sort_field, () => props.sort_order], () => {
      comps.value = sortCompetitors();
      gridKey.value++;
    });

    const addNewCompetitor = async (competitorData) => {
      try {
        const response = await axios_client.post(`/events/${props.event_id}/markets/${props.market_id}/competitors`,
          {
            competitor: competitorData
          },
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        message.success(`New Competitor for ${props.competitors[0].market.name} was successfully created.`);
        fetchCompetitors();
      } catch (error) {
        message.error("Failed to add competitor.");
      }
    };

    const fetchCompetitors = async () => {
      try {
        const response = await axios_client.get(`/events/${props.event_id}/markets/${props.market_id}/competitors.json`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const competitors = response.data;
        comps.value = competitors.map(c => ({
          ...c,
          price: parseFloat(c.latest_price?.decimal)
        }));
        comps.value = sortCompetitors();
        gridKey.value++;
      } catch (error) {
        console.log(error);
        message.error("Failed to fetch competitors");
      }
    }

    return {
      showNewModal,
      addNewCompetitor,
      comps,
      fetchCompetitors,
      gridColumns,
      comps,
      gridKey
    }
  }
}
</script>