<template lang="pug">
  n-flex(justify="space-between")
    n-h3 Results
    n-button-group
      n-button(size="small" @click="showNewModal = true") + NEW
      n-button(size="small" @click="handleImport" :loading="loading") IMPORT SL RESULT
    n-data-table(:columns="columns" :data="data" :pagination="pagination" @update:sorter="handleUpdateSorter")
  n-modal(v-model:show="showNewModal" transform-origin="center")
    n-card(style="width: 600px" title="Create New Result" :bordered="false" size="huge" role="dialog" aria-modal="true")
      n-form(ref="resultForm" :model="model" :rules="rules" label-placement="top" size="large")
        n-h4 {{ market.name }}
        n-form-item(label="Competitor" path="competitor")
          n-select(v-model:value="model.competitor" :options="competitorOptions" size="large" placeholder="Choose Competitor")
        n-form-item(label="Position" path="position")
          n-select(v-model:value="model.position" :options="positionOptions" size="large" placeholder="Choose Position")
        n-form-item(label="Decimal" path="decimal")
          n-input(v-model:value="model.decimal" type="text" placeholder="Decimal" @update:value="onDecimalInput")
        n-form-item(label="Fractional" path="fractional")
          n-input(v-model:value="model.fractional" type="text" placeholder="Fractional" @update:value="onFractionalInput")
        n-form-item(label="Fav Label" path="fav_label")
          n-select(v-model:value="model.fav_label" :options="favLabelOptions" size="large" placeholder="Choose Fav Label")
        n-flex(justify="end")
          n-button(type="primary" @click="submitForm") SAVE
          n-button(type="error" @click="showNewModal = false") CANCEL
</template>

<script>
import { useDialog, useMessage } from 'naive-ui';
import { ref, computed, onMounted, watchEffect, h } from 'vue'
import axios_client from '../../../utils/axios_client';
import { NButton, NButtonGroup, NText, NTag } from 'naive-ui'


const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default {
  props: {
    market: {
      type: Object,
      required: true,
    },
    results: {
      type: Array,
      required: true,
    }
  },
  emits: ['refresh-data'],
  setup(props, { emit }) {
    const message = useMessage();
    const dialog = useDialog();
    const data = ref([]);
    const loadingRef = ref(false)
    const sortStatesRef = ref([])
    const value = ref(null);
    const editId = ref(null);
    const sortKeyMapOrderRef = computed(() =>
      sortStatesRef.value.reduce((result, { columnKey, order }) => {
        result[columnKey] = order
        return result
      }, {})
    )
    const paginationRef = ref({ pageSize: 15 })
    const showNewModal = ref(false);
    const resultForm = ref(null);
    const model = ref({
      competitor: null,
      position: null,
      decimal: null,
      fractional: null,
      fav_label: null,
    });

    const rules = {
      competitor: [
        { required: true, message: 'Competitor is required', trigger: ['blur', 'change'] }
      ],
      position: [
        { required: true, message: 'Position is required', trigger: ['blur', 'change'] }
      ],
      decimal: [
        { required: true, message: 'Decimal value is required', trigger: ['blur', 'input'] },
        { pattern: /^\d+(\.\d+)?$/, message: 'Invalid decimal format', trigger: ['blur', 'input'] }
      ],
      fractional: [
        { required: true, message: 'Fractional value is required', trigger: ['blur', 'input'] },
        { pattern: /^(0|[1-9]\d*)\/(?!0+$)(0|[1-9]\d*)$/, message: 'Invalid fractional format', trigger: ['blur', 'input'] }
      ]
    };

    const favLabelOptions = ref([]);
    const competitorOptions = computed(() =>
      props.market.competitors.map(c => ({ label: c.name, value: String(c.id) }))
    );

    const positionOptions = computed(() =>
      Array.from({ length: props.market.competitors.length }, (_, i) => ({ label: i + 1, value: String(i + 1) }))
    );

    watchEffect(() => {
      data.value = props.results?.map(item => ({
        id: item.id,
        position: item.position,
        competitor_id: item.competitor.id,
        name: item.competitor.name,
        fractional: item.fractional,
        decimal: item.decimal,
        fav_label: item.fav_label,
        created_at: item.competitor.event.created_at
      }));
    });

    const columnsRef = computed(() => [
      {
        title: 'Pos',
        key: 'position',
        sortOrder: sortKeyMapOrderRef.value.pos || false,
        sorter(rowA, rowB) {
          return rowA.pos - rowB.pos
        }
      },
      {
        title: 'Name',
        key: 'name',
        render(row) {
          return h('div', [
            h(NText, { strong: true }, { default: () => row.name }),
            row.fav_label ? h(NTag, { type: 'info', style: { marginLeft: '8px' }, round: 'true' }, { default: () => row.fav_label }) : null
          ]);
        }
      },
      {
        title: 'Price',
        key: 'fractional',
      },
      {
        title: '',
        key: 'action',
        render(row) {
          return h(
            NButtonGroup,
            {},
            {
              default: () => [
                h(
                  NButton,
                  {
                    size: 'tiny',
                    type: 'info',
                    ghost: true,
                    onClick: () => { handleShow(row.id) }
                  },
                  { default: () => h('i', { class: 'fas fa-eye' }) }
                ),
                h(
                  NButton,
                  {
                    size: 'tiny',
                    type: 'info',
                    ghost: true,
                    onClick: () => { handleEdit(row) }
                  },
                  { default: () => h('i', { class: 'fas fa-edit' }) }
                ),
                h(
                  NButton,
                  {
                    size: 'tiny',
                    type: 'error',
                    ghost: true,
                    onClick: () => {
                      dialog.warning({
                        title: 'Confirm delete?',
                        content: 'Are you sure want to remove the result?',
                        positiveText: 'Yes',
                        negativeText: 'No',
                        draggable: true,
                        onPositiveClick: () => {
                          performDelete(row.id);
                        },
                      })
                    }
                  },
                  { default: () => h('i', { class: 'fas fa-trash' }) }
                )
              ]
            }
          )
        }
      }
    ])
    function onDecimalInput(val) {
      model.value.decimal = val
      const dec = parseFloat(val)
      if (!isNaN(dec) && dec > 1) {
        const fraction = decimalToFraction(dec - 1)
        model.value.fractional = `${fraction.numerator}/${fraction.denominator}`
      }
    }
    function onFractionalInput(val) {
      model.value.fractional = val
      const parts = val.split('/')
      const numerator = parseInt(parts[0])
      const denominator = parseInt(parts[1])
      if (parts.length === 2 && !isNaN(numerator) && !isNaN(denominator) && denominator !== 0) {
        model.value.decimal = ((numerator / denominator) + 1).toFixed(2)
      }
    }

    function handleUpdateSorter(sorters) {
      sortStatesRef.value = [].concat(sorters)
    }

    const handleImport = async () => {
      loadingRef.value = true;
      try {
        const response = await axios_client.get(`${props.market.id}/import_sporting_life_results`);

        if (response.data.success) {
          message.success("Market results are imported");
          emit('refresh-data');
        } else {
          message.error("Market is not exist");
        }
      } catch (error) {
        console.error(
          'Error fetching market:',
          error.response ? error.response.data : error.message,
        );
      }
      loadingRef.value = false;
    }

    const fetchFavLabels = async () => {
      try {
        const response = await axios_client.get(`${props.market.id}/results/fav_labels`);

        if (response.data) {
          favLabelOptions.value = Object.entries(response.data).map(([key, value]) => ({ label: key, value }))
        } else {
          message.error("Error in Fav labels");
        }
      } catch (error) {
        console.error(
          'Error fetching fav labels:',
          error.response ? error.response.data : error.message,
        );
      }
    }

    const clearValidation = (field) => {
      resultForm.value?.restoreValidation?.([field]);
    };

    const submitForm = () => {
      resultForm.value.validate((errors) => {
        if (!errors) {
          saveResult();
        }
      });
    };

    const saveResult = async () => {
      try {
        const payload = {
          result: {
            competitor_id: model.value.competitor,
            position: model.value.position,
            decimal: model.value.decimal,
            fractional: model.value.fractional,
            fav_label: model.value.fav_label
          }
        };

        if (!editId.value) {
          await axios_client.post(`${props.market.id}/results`, payload, { headers: { 'X-CSRF-Token': csrfToken } });
          message.success("Result created successfully");
        } else {
          await axios_client.patch(`${props.market.id}/results/${editId.value}`, payload, { headers: { 'X-CSRF-Token': csrfToken } });
          message.success("Result updated successfully");
          editId.value = null;
        }
        emit('refresh-data');
        showNewModal.value = false;
      } catch (error) {
        console.error(error);
        message.error("Failed to save the result.");
      }
    };

    const handleShow = (id) => {
      window.location.href = `/events/${props.market.event_id}/markets/${props.market.id}/results/${id}`;
    }

    const handleEdit = (row) => {
      model.value.competitor = String(row.competitor_id)
      model.value.position = String(row.position);
      model.value.decimal = row.decimal;
      model.value.fractional = row.fractional;
      model.value.fav_label = row.fav_label;
      editId.value = row.id;
      showNewModal.value = true;
    }

    const performDelete = async (id) => {
      try {
        const response = await axios_client.delete(`/events/${props.market.event_id}/markets/${props.market.id}/results/${id}.json`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        message.success("Result deleted successfully!");
        emit('refresh-data');

      } catch (error) {
        console.error(error);
        message.error("Error while deleting result.");
      }
    }


    function decimalToFraction(decimal) {
      const tolerance = 1.0E-6
      let h1 = 1, h2 = 0, k1 = 0, k2 = 1
      let b = decimal
      do {
        const a = Math.floor(b)
        let aux = h1
        h1 = a * h1 + h2
        h2 = aux
        aux = k1
        k1 = a * k1 + k2
        k2 = aux
        b = 1 / (b - a)
      } while (Math.abs(decimal - h1 / k1) > decimal * tolerance)

      return { numerator: h1, denominator: k1 }
    }

    onMounted(() => {
      fetchFavLabels();
    })

    return {
      columns: columnsRef,
      handleUpdateSorter,
      data,
      pagination: paginationRef,
      showNewModal,
      value,
      competitorOptions,
      positionOptions,
      favLabelOptions,
      handleImport,
      loading: loadingRef,
      model,
      rules,
      resultForm,
      saveResult,
      submitForm,
      clearValidation,
      onDecimalInput,
      onFractionalInput
    }
  }

};
</script>