<template lang="pug">
  n-flex(vertical style="margin-top: 10px; margin-bottom: 10px")
    n-h2 Competitors
    n-scrollbar(ref="scrollbarRef" style="max-height: 630px")
      CompetitorItem(v-for="(c, index) in competitors" :key="index" v-model:competitor="competitors[index]")
    n-button(type="primary" @click="addCompetitor" :disabled="oldComps?.length > 0") ADD COMPETITOR
</template>

<script>
import { ref, computed, onMounted, onUnmounted, nextTick, watchEffect, watch, provide } from 'vue'
import CompetitorItem from './competitor_item.vue';

export default {
  components: { CompetitorItem },
  props: {
    oldComps: {
      type: Array,
      required: false,
    },
    is_edit: {
      type: String,
      required: true,
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const competitors = ref([]);

    const scrollbarRef = ref(null);
    const screenWidth = ref(window.innerWidth);

    const updateWidth = () => {
      screenWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
      if (props.is_edit === 'false') {
        addCompetitor();
      }
    });
    onUnmounted(() => window.removeEventListener('resize', updateWidth));

    const isMobile = computed(() => screenWidth.value < 768);

    watchEffect(() => {
      if (props.oldComps) {
        competitors.value = props.oldComps.map(c => ({
          id: c.id,
          card_number: c.card_number,
          name: c.name,
          external_id: c.external_id,
          metadata: typeof c.metadata === "string" ? c.metadata : JSON.stringify(c.metadata),
          active: c.active,
          locked: c.locked,
          price: c.latest_price,
        }));
      }
    });

    watch(competitors, (newValue) => {
      emit("update:modelValue", newValue);
    }, { deep: true });

    const addCompetitor = () => {
      competitors.value.push({
        card_number: competitors.value.length + 1,
        name: '',
        external_id: null,
        metadata: '{}',
        active: true,
        locked: false,
      });
      nextTick(() => {
        if (scrollbarRef.value) {
          scrollbarRef.value.scrollTo({ top: 9999, behavior: 'smooth' });
        }
      });
    };

    const addCompetitorFromChild = () => {
      if (props.is_edit === 'false') {
        addCompetitor();
      }
    }
    provide('addCompetitor', addCompetitorFromChild);

    return {
      isMobile,
      competitors,
      addCompetitor,
      scrollbarRef
    };
  }
};
</script>
