<template lang="pug">
    n-flex(justify="space-between")
      n-h3 Non Runners
      n-data-table(:columns="columns" :data="data" :pagination="pagination")
      
</template>

<script>
import { ref, computed, h, watchEffect } from 'vue'
import { NText, NButton, useDialog, useMessage } from 'naive-ui'
import moment from 'moment-timezone';
import axios_client from '../../../utils/axios_client';

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default {
  props: {
    nonRunners: {
      type: Array,
      required: true,
    },
  },
  emits: ['refresh-data'],
  setup(props, { emit }) {
    const data = ref([]);
    const dialog = useDialog();
    const message = useMessage();
    const paginationRef = ref({ pageSize: 5 })

    watchEffect(() => {
      data.value = [...props.nonRunners];
    })
    const columnsRef = computed(() => [
      {
        title: '',
        key: 'created_at',
        render(row) {
          return formatTime(row.created_at)
        }
      },
      {
        title: '',
        key: 'name',
      },
      {
        title: '',
        key: 'action',
        render(row) {
          return h(
            NButton,
            {
              size: 'tiny',
              type: 'warning',
              ghost: true,
              onClick: () => {
                dialog.warning({
                  title: 'Confirm remove?',
                  content: 'Are you sure want to remove the non-runner?',
                  positiveText: 'Yes',
                  negativeText: 'No',
                  draggable: true,
                  onPositiveClick: () => {
                    performRemoveNonrunner(row);
                  },
                })
              }
            },
            { default: () => h('i', { class: 'fas fa-trash' }) }
          )
        }
      }
    ])

    const formatTime = (time) => {
      return time ? moment(time).tz("Europe/Dublin").format("D MMM YYYY HH:mm:ss") : "";
    };

    const performRemoveNonrunner = async (row) => {
      try {
        const response = await axios_client.get(`/events/${row.event_id}/markets/${row.market_id}/competitors/${row.id}/mark_as_non_runner`,
          { headers: { 'X-CSRF-Token': csrfToken } }
        );
        const result = response.data;
        if (!result.non_runner)
          message.success(`The non runner was removed`);
        emit('refresh-data');
      } catch (error) {
        console.log(error);
        message.error("Failed to remove non runner.");
      }
    }

    return {
      data,
      pagination: paginationRef,
      columns: columnsRef,
    }
  }

};
</script>