<template lang="pug">
    n-flex(justify="space-between")
      n-h3 Rule 4 Deductions
      n-data-table(:columns="columns" :data="data" :pagination="pagination")

</template>

<script>
import { ref, computed, onMounted, h, watchEffect } from 'vue'
import moment from 'moment-timezone';
import { NText } from 'naive-ui'


export default {
  props: {
    deductions: {
      type: Array,
      required: true,
    }
  },
  setup(props) {
    const data = ref([]);
    const paginationRef = ref({ pageSize: 5 })

    watchEffect(() => {
      data.value = [...props.deductions];
    })
    const columnsRef = computed(() => [
      {
        title: '',
        key: 'created_at',
        render(row) {
          return formatTime(row.created_at)
        }
      },
      {
        title: '',
        key: 'name',
        render(row) {
          return row.competitor?.name
        }
      },
      {
        title: '',
        key: 'amount',
        render(row) {
          return h(
            NText,
            {
              type: "error"
            },
            {
              default: () => row.amount
            }
          )
        }
      },
    ])

    const formatTime = (time) => {
      return time ? moment(time).tz("Europe/Dublin").format("D MMM YYYY HH:mm:ss") : "";
    };

    return {
      data,
      columns: columnsRef,
      pagination: paginationRef
    }
  }

};
</script>