<template lang="pug">
  n-form(ref="competitorForm" :model="formCompetitor" :rules="rules" label-placement="top" size="large")
    n-flex(vertical style="position: relative; padding:10px 10px 0 10px; border: solid 2px #f0f0f0; border-radius:10px; margin-bottom: 10px;")
      n-flex(vertical)
        n-flex
          n-form-item(label="Card number" path="card_number" style="flex: 1;")
            n-input-number(v-model:value="formCompetitor.card_number" min="0")
          n-form-item(label="Name" path="name" style="flex: 2;")
            n-input(v-model:value="formCompetitor.name" @keyup.enter="focusPriceInput" ref="nameInputRef")
          n-form-item(label="Price" path="price.fractional" style="flex: 1;")
            n-input(v-model:value="formCompetitor.price.fractional" @keyup.enter="handlePriceEnter" ref="priceInputRef")
        n-switch(v-model:value="show" style="position: absolute; top: 10px; right: 10px; z-index: 1")
          template(#checked) More
          template(#unchecked) Hide

      n-collapse-transition(:show="show")
        n-flex(style="width: 100%; align-items:center")
          n-form-item(label="External" path="external_id")
            n-input-number(v-model:value="formCompetitor.external_id" min="0")
          n-space(align="center")
            n-space(justify="space-between" style="width: 100%")
              n-text Active
              n-switch(v-model:value="formCompetitor.active")
            n-space(justify="space-between" style="width: 100%")
              n-text Price Locked
              n-switch(v-model:value="formCompetitor.locked")
        n-flex(style="width: 100%" align="center")
          n-form-item(label="Metadata" path="metadata" style="flex-grow: 1")
            n-input(v-model:value="formCompetitor.metadata" type="textarea" rows="2" placeholder="Metadata")
  </template>

<script>
import { ref, watch, defineComponent, watchEffect, nextTick, onMounted, inject } from 'vue';

export default defineComponent({
  props: {
    competitor: {
      type: Object,
      required: true
    }
  },
  emits: ['update:competitor'],
  setup(props, { emit }) {
    const competitorForm = ref(null);
    const show = ref(false);
    const fractional = ref(props.competitor.price?.fractional);
    const priceInputRef = ref(null);
    const nameInputRef = ref(null);
    const formCompetitor = ref({
      ...props.competitor,
      price: {
        fractional: props.competitor.price?.fractional ?? 'SP'
      }
    });
    const fractionalRegex = /^\d+\/\d+(\.\d+)?$/;
    const addCompetitor = inject('addCompetitor');

    const rules = {
      card_number: [
        { required: false, type: 'number', min: 0, message: 'Min value : 0', trigger: ['blur', 'input'] }
      ],
      external_id: [
        { required: false, type: 'number', min: 0, message: 'Min value : 0', trigger: ['blur', 'input'] }
      ],
      metadata: [
        {
          validator(rule, value) {
            try {
              JSON.parse(value);
              return true;
            } catch (error) {
              return new Error('Metadata must be valid JSON');
            }
          },
          trigger: ['blur', 'input']
        }
      ],
      'price.fractional': [
        {
          validator(rule, value) {
            if (!value) return true;
            if (value === 'SP' || fractionalRegex.test(value)) {
              return true;
            }
            return new Error('Price must be in fractional format, e.g. 5/2');
          },
          trigger: ['blur', 'input']
        }
      ]
    };

    onMounted(() => {
      nameInputRef.value.focus();
    })

    watch(formCompetitor, (newForm) => {
      emit('update:competitor', {
        ...newForm,
        prices_attributes: {
          "1": {
            fractional: newForm.price?.fractional
          }
        }
      });
    }, { deep: true });

    const focusPriceInput = () => {
      nextTick(() => {
        if (priceInputRef.value) {
          priceInputRef.value.focus();
          priceInputRef.value.select();
        }
      });
    };

    const handlePriceEnter = () => {
      competitorForm.value?.validate((errors) => {
        if (!errors) {
          addCompetitor();
        }
      });
    };

    return {
      competitorForm,
      formCompetitor,
      rules,
      show,
      fractional,
      nameInputRef,
      priceInputRef,
      handlePriceEnter,
      focusPriceInput
    };
  }
});
</script>
